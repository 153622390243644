import { Rating } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

const ConfirmationModal = ({ modalShow, setModalShow,isSubmit,fun,closeBtnName,actionBtnName,title,body }) => {
    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                className='modal-all'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>{body}</h5>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-secondary' onClick={() => {setModalShow(false)}}>{closeBtnName}</button>
                    {isSubmit
                        ? <button className='btn btn-danger'>
                            <Spinner style={{ height: '20px', width: '20px' }} />
                        </button>
                        : <button className='btn btn-danger' onClick={() => fun() }>{actionBtnName}</button>
                    }
                    
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ConfirmationModal