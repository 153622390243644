import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <Button
        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium 
        MuiButton-colorPrimary MuiButton-disableElevation artist-btn css-1csj07v"
        onClick={() => navigate(-1)}
        tabIndex="0"
      >
        <i className="fa-solid fa-arrow-left"></i>
        <span className="MuiTouchRipple-root css-w0pj6f"></span>
      </Button>
    </>
  );
};

export default BackButton;
