import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import eng from "./eng.json";
import arabic from "./arabic.json";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: eng },
      ar: { translation: arabic }
    },
    lng: "en", // initial language
    fallbackLng: "en",
    interpolation: { escapeValue: false }
  });

export default i18n;
