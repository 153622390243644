import { lazy } from 'react';
import  Loadable  from '../components/Loadable';
import  Dashboard  from '../layout/Dashboard';
import Users from '../pages/user-management/Users';
import Rating from '../pages/user-management/Rating';
import UserDetail from '../pages/user-management/UserDetail';
import SchoolDetailsEdit from '../pages/user-management/SchoolDetailsEdit';
import TransportDetailsEdit from '../pages/user-management/TransportDetailsEdit';
import VehicleEdit from '../pages/user-management/VehicleEdit';
import SubscriptionPlansList from '../pages/subscriptionPlans/SubscriptionPlansList';
import PlanEdit from '../pages/subscriptionPlans/PlanEdit';

const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard/index')));
const MainRoutes = {
  path: '/',
  element: <Dashboard />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: '/dashboard',
      element: <DashboardDefault />
    },
    {
      path: '/list/:role',
      element: <Users />
    },
    {
      path: '/ratings',
      element: <Rating />
    },
    {
      path: '/user-details/:id',
      element: <UserDetail />
    },
    {
      path: '/school-details-edit/:id',
      element: <SchoolDetailsEdit />
    },
    {
      path: '/transport-details-edit/:id',
      element: <TransportDetailsEdit />
    },
    {
      path: '/vehicle-edit/:id',
      element: <VehicleEdit />
    },
    {
      path: '/plan-edit/:id',
      element: <PlanEdit />
    },
    {
      path: '/subscription-plans',
      element: <SubscriptionPlansList />
    },
  ]
};

export default MainRoutes;
