import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Form, Row, Col, Button, CardFooter } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { APICALL } from '../../../helper/api/api';
import { imgBaseURL, toastifySuccess } from '../../../helper/Utility';
import ConfirmationModal from '../ConfirmationModal';
import AdminLoader from '../../components/AdminLoader';
import BackButton from '../BackButton';
import { useTranslation } from 'react-i18next';

const PlanEdit = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [imgList, setImgList] = useState([])
  const [loading, setLoading] = useState(false);
  const data = location.state;

  console.log(data);

  const [formData, setFormData] = useState({
    name: '',
    durationInMonths: '',
    price: '',
    whatIncluded: '',
    image: null,
  });


  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "image") {
      setFormData((prevState) => ({
        ...prevState,
        image: files[0],
      }));
    } else{
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const fetchData = () => {
      const initialData = {
        planId: data?._id,
        name: data?.name,
        durationInMonths: data?.durationInMonths || '',
        price: data?.price || '',
        whatIncluded: data?.whatIncluded || '',
        image : null,
      };
      setFormData(initialData);
    };

    fetchData();
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const perms = new FormData();

      perms.append('planId', formData?.planId);
      perms.append('name', formData.name);
      perms.append('durationInMonths', formData.durationInMonths);
      perms.append('price', formData.price);
      perms.append('whatIncluded', formData.whatIncluded);
      perms.append('image', formData.image);

      const res = await APICALL("admin/createPlan", "post", perms);

      if (res?.status) {
        setLoading(false);
        navigate(-1);
        toastifySuccess(res?.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Card className="card-cusotom card">
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center" style={{ gap: '10px' }}>
            <div className="d-flex align-items-center" style={{ gap: '10px' }}>
              <BackButton />
              <h2 className="title-admins-table m-0">{t('Edit Plan Details')}</h2>
            </div>
          </div>
        </CardHeader>
        {loading ? 
        <>
        <AdminLoader />
        </> : 
          <Form onSubmit={handleSubmit}>
        <CardBody>
        <div className='cutoms-login-artist'>
            <Row>
              <Col md={4} className="mb-4">
                <Form.Group class="mb-3" controlId="name">
                  <Form.Label>{t('Name')}</Form.Label>
                  <Form.Control
                    type="text"
                    name='name'
                    value={formData.name}
                    onChange={handleChange}
                    placeholder={t('Name')}
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group class="mb-3" controlId="durationInMonths">
                  <Form.Label>{t('Duration In Months')}</Form.Label>
                  <Form.Control
                    type="text"
                    name='durationInMonths'
                    value={formData.durationInMonths}
                    onChange={handleChange}
                    placeholder={t('Duration In Months')}
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group class="mb-3" controlId="durationInMonths">
                  <Form.Label>{t('Price')}</Form.Label>
                  <Form.Control
                    type="text"
                    name='price'
                    value={formData.price}
                    onChange={handleChange}
                    placeholder={t('Price')}
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group class="mb-3" controlId="durationInMonths">
                  <Form.Label>{t('What Included')}</Form.Label>
                  <textarea
                    type="text"
                    className='form-control'
                    name='whatIncluded'
                    value={formData.whatIncluded}
                    onChange={handleChange}
                    placeholder={t('What Included')}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group class="mb-3" controlId="image">
                  <Form.Label>{t('Image')}</Form.Label>
                  <Form.Control
                    type="file"
                    name='image'
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              {data?.image && 
              <Col md={3}>
                 <img
                    src={imgBaseURL() + data?.image}
                    alt="Vehicle gallery"
                    width="150px"
                    height="150px"
                    style={{ border: '1px solid black', marginLeft: '10px' }}
                  />
              </Col>
              }
            </Row>
            </div>
        </CardBody>
        <CardFooter>
        <Button type="submit" className="artist-btn btn btn-primary">
          {t('Update')}
        </Button>
        </CardFooter>
          </Form>
        }
      </Card>
    </>
  );
}

export default PlanEdit;
